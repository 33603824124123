"use client"
import React, { useEffect, useRef, useState } from "react";
import { ContextData, _logoutFunction } from "@/services/Methods/normalMethods";
import { _storeCustomerTypes } from "@/services/Methods/commonPayload";
import localStorageCall from "@/services/Methods/localstorage.hook";
import { _getCountryCodeApi } from "@/services/Redux/Reducer/CustomerSlice";
import { createSelector } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import withRouteHOC from "@/HOC/withRouterHOC"
import withMiniCartHOC from "@/HOC/withMinCartHOC";
import _ from "lodash";
// import "react-datepicker/dist/react-datepicker.css";
import { getOrders } from "@/services/Redux/Reducer/OrderSlice";
import Header from "@/comman/Header/Header";
import ShoppingCartComponent from "@/comman/ShoppingCartComponent";
import Footer from "@/comman/Footer/Footer";
import useScript from "../../services/Methods/UseScript.hook";
import JoinPopup from "@/comman/JoinPopup";
import * as Sentry from "@sentry/react";

const STORES = ["us", "ca"];

const Template = ({ children, ...props }) => {
    const { pathname, searchParams, params, redirect, isUserLogin, checkoutRef } = props;
    const [showCountries, setShowCountries] = useState(false);
    const dispatch = useDispatch();
    const menuRef = useRef(null);
    const buttonRef = useRef(null);
    const [orders, setOrders] = useState([])
    const [selectedStore, setSelectedStore] = useState({ defaultCode: 'us', newCode: 'us' });

    const handleOutsideClick = (event) => {
        if (
            menuRef.current &&
            !menuRef.current.contains(event.target) &&
            event.target !== buttonRef.current
        ) {
            setShowCountries(false);
        }
    };
    useEffect(() => {
        localStorageCall().removeItem('activeStep');
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const MEMBERSHIP_AVAILABLE = _.find(localStorageCall().getItem('normalProducts'), { frequency: "member" }) || null;

    if (!_.includes(pathname, 'main-enrollment') && MEMBERSHIP_AVAILABLE) {
        localStorageCall().removeItem('normalProducts');
    }


    const getData = createSelector(
        state => state.CustomerSlice,
        state => state?.LoginSlice?.alluserData,
        state => state?.PaymentSlice?.paymentScript,
        ({ countryData, isLoading }, userData, paymentScript) => ({
            isLoading,
            countryCodes: _.map(countryData, 'countryCode')?.length > 0 ? _.map(countryData, 'countryCode') : [],
            countyObjects: _.map(countryData, (row) => ({ code: row?.countryCode, name: row?.countryName, currency: row?.currency })),
            userData: userData, paymentScript,
        })
    );

    const { isLoading, countryCodes, countyObjects, paymentScript, userData } = useSelector((state) => getData(state));

    // useEffect(() => {
    //     console.log("Country Data:", countryData);
    // }, [countryData]);

    useScript(paymentScript);

    useEffect(() => { dispatch(_getCountryCodeApi()); }, []);

    useEffect(() => {

        const DEFAULT_STORE = localStorageCall()?.getSingleItem('store');
        if (!DEFAULT_STORE) {
            if (STORES?.includes(params?.store)) {
                localStorageCall().setItem('store', params?.store)
            }
        } else {
            if (DEFAULT_STORE !== params?.store) {
                if (STORES?.includes(params?.store)) {
                    localStorageCall().setItem('store', params?.store);
                    setSelectedStore({ defaultCode: params?.store, newCode: params?.store });
                } else {
                    setSelectedStore({ defaultCode: DEFAULT_STORE, newCode: DEFAULT_STORE });
                }
            } else {
                setSelectedStore({ defaultCode: DEFAULT_STORE, newCode: DEFAULT_STORE });
            }
        }

        const script = document.createElement('script');
        script.src = "https://cdn.weglot.com/weglot.min.js";
        script.onload = () => {
            Weglot.initialize({
                api_key: 'wg_dcb2ef458c9a4e857e5eefd844a45f9d0'
            });
        };
        document.head.appendChild(script);
        return () => {
            document.head.removeChild(script);
        }

    }, [pathname]);


    useEffect(() => {
        if (STORES?.includes(params?.store)) {
            localStorageCall().setItem('store', params?.store)
        }

    }, [pathname]);

    useEffect(() => {
        if (isUserLogin && userData === null) {
            _storeCustomerTypes(dispatch, isUserLogin, searchParams);
        }

        // dispatch(getOrders(null, isUserLogin, (res) => {
        //     setOrders(res?.data)
        // }))
    }, [isUserLogin]);


    const [joinPopup, setJoinPopup] = useState(false);

    if (userData?.emailAddress) {
        Sentry.setUser({ id: userData?.customerId, email: userData?.emailAddress });
        Sentry.setTag("userID", userData?.customerId);
    }

    return (
        <>
            <ContextData.Provider value={{
                showCountries, userData, countryCodes, setShowCountries, countyObjects, dispatch, STORES, handleOutsideClick,
                buttonRef, menuRef, orders, selectedStore, setSelectedStore, checkoutRef, ...props
            }
            }>

                <Header  {...{
                    showCountries, userData, countryCodes, setShowCountries, countyObjects, dispatch, STORES, handleOutsideClick, buttonRef,
                    menuRef, joinPopup, setJoinPopup, selectedStore, setSelectedStore,
                    ...props
                }} />

                <div>{children}</div>
                <Footer {...{ joinPopup, setJoinPopup, userData }} />
                <ShoppingCartComponent
                    section={_.includes(pathname, 'distributor') ? 'distributor' : 'normal'}
                    pathname={pathname}
                    _changeFrequencyOfProducts={props?._changeFrequencyOfProducts}
                    _minCartQuantityIncDec={props?._minCartAndViewCartQuantityIncDec}
                    openMiniCart={props?.openMiniCart}
                    setOpenMiniCart={props?.setOpenMiniCart}
                    _deleteProductFromCart={props?._deleteProductFromCart}
                    productData={props?.addToCartProducts?.normalProducts}
                    _autoShipCheck={props?._autoShipCheck} />
            </ContextData.Provider>
            <JoinPopup {...{ joinPopup, setJoinPopup }} />
        </ >
    );
}

export default withRouteHOC(withMiniCartHOC(Template, 'normal'));